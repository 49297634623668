<template>
  <router-link to="/" class="logo u-block">
    <img
      src="/images/logo--transparent.png"
      alt="Bonjour RATP"
    />
  </router-link>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style lang="stylus" scoped>

.logo
  img
    width 100%

@media screen and (max-width: 1300px)
  .logo
    width 70px
    display inline-block
@media screen and (max-width: 800px)
  .logo
    width 108px
    display inline-block
</style>

<template>
  <div class="message" :class="[look ? 't-h3-light' : 't-h3']">
    <div class="message-content" :class="[look]">
      <div v-if="name" class="message-name">
        {{ name }}
      </div>
      <div class="message-text" v-html="text" />
      <div class="message-button" @click="action">
        Continuer
        <img src="../../assets/arrow-right.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { audio } from '@/utils/Audio'

export default {
  name: "MessageToast",

  props: {
    look: String,
    name: String,
    text: String,
  },

  methods: {
    action() {
      // Should be in the parent compo ? (on the emmitted @next) 
      if(this.name === "Malik"){
        audio.src = this.$store.state.global.sounds.malik
        audio.play()
      }

      this.$emit("next")
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'

.message
  display flex
  justify-content center
  align-items center
  position relative
  z-index 100
@media screen and (max-width: 800px)
  .message
    max-width 100%
    width 100%

.message-content
  padding 20px 40px 36px
  display flex
  flex-direction column
  min-width: 450px;
  max-width: 1000px;
  background rgba(255, 255, 255, 0.6)
  backdrop-filter blur(12px) // no support on FF
  border-radius 12.28px
  color #4A53A0

  &.blue
    color #fff
    background rgba(#5E639E, 0.4)

  &.green
    color $colors.green
    // background: rgba($colors.green, 0.4)

@media screen and (max-width: 1300px)
  .message-content
    max-width: 750px;

@media screen and (max-width: 800px)
  .message-content
    width 100%
    padding 24px 26px
    min-width: 100%;


.message-name
  text-transform uppercase
  margin-bottom 10px

.message-button
  margin-top 40px
  padding 10px 20px
  background #FFFFFF
  border-radius 18px
  align-self flex-end
  cursor pointer
  border 2px solid transparent
  color #202020

  &:hover
    border-color currentColor

@media screen and (max-width: 800px)
  .message-button
    align-self center
    margin-top 30px
    
</style>

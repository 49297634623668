<template>
  <div class="u-z-front phone">
    <img :src="url" class="phone__img" />
    <div class="keypoint u-cursor-pointer" @click="onNext">
      <div class="keypoint__main u-box-1by1" />
      <div class="keypoint__pulse" />
      <div class="keypoint__heart" />
    </div>
    <img src="/images/phone.png" class="u-full-width u-relative u-pointer-none">
  </div>
</template>

<script>
import { audio } from '@/utils/Audio'

export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },

  methods: {
    onNext () {
      audio.src = this.$store.state.global.sounds.nextScreen
      audio.play()

      this.$emit('next')
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

$pink = #F1CDC5
$blue = #08007C

.phone
  position absolute
  top 180px
  right 20px
  width clamp(20%, 200px, 400px)
  height auto
  
  &__img
    position absolute
    top 11%
    left 5.3%
    width 91%
    
.keypoint
  border-radius 50%
  width 60px
  opacity 1
  position absolute
  left calc(50% - 20px)
  top 32%

  &__main
    border-radius 50%
    background-color $blue
    opacity .5

  &__heart
    position absolute
    background: $pink
    height 68%
    width 68%
    top 16%
    left 16%
    border-radius 50%

  &__pulse
    position absolute
    border solid 4px $pink
    height 130%
    width 130%
    top -15%
    left -15%
    border-radius 50%
    opacity 0
    will-change opacity transform
    animation 2s ease-in-out-quad infinite pulse

@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0

</style>
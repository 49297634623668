<template>
  <div class="chapter">
    <div class="chapter-number">
      <span>{{ formatNumber(number) }}</span>      
    </div>
    <div class="chapter-text t-h3">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ChapterToast",

  props: {
    number: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: ''
    }
  },

  methods: {
    formatNumber (number) {
      if (number < 10) {
        return '0' + number.toString()
      }
      return number
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'

.chapter
  background $colors.green
  border-radius 32px
  padding 26px
  display flex
  align-items center
  width 100%

.chapter-number
  font-size 36px
  font-weight 700
  line-height 24px
  color $colors.yellow
  background white
  border-radius 50%
  height 78px
  display flex
  text-align: center;
  justify-content center
  align-items center
  
  span
    display: inline-block;
    width: 78px;

@media screen and (max-width: 1300px)
  .chapter-number
    font-size 28px
    line-height 20px
    height 50px

    span
      width: 50px;

@media screen and (max-width: 800px)
  .chapter-number
    font-size 21px
    line-height 14px
    height 46px

    span
      width: 46px;

.chapter-text
  padding-left 24px
  color #FFFFFF
@media screen and (max-width: 800px)
  .chapter-text
    padding-left 12px
</style>

import { createWebHistory } from 'vue-router'

import HelloWorld from './components/HelloWorld'
import Introduction from './components/pages/Introduction'
import Chapters from './components/pages/Chapters'
import messages from './locales'

const routes = [
  {
    path: '',
    name: 'introduction',
    component: Introduction,
    meta: {
      isFull: true
    }
  },
  {
    path: 'chapters/:section',
    name: 'chapters',
    component: Chapters
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}

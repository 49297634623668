<template>
  <div class="quizz">
    <div class="quizz-entry">
      <div class="question">
        <div class="question-number">Question {{ formatNumber(data.indice) }}</div>
        <div class="question-text t-h3">{{ data.title }}</div>
      </div>

      <div
        v-for="(answer, propositionIndex) in data.answers"
        :key="answer"
        class="proposition t-h3"
        :class="{
          valid: !hasSeenInfo && hasAnswered && answer.value,
          info: hasSeenInfo && answer.value,
          invalid: hasAnswered && !answer.value,
          hidden: hasSeenInfo && !answer.value,
        }"
        @click="tryAnswer(answer)"
      >
        {{ answer.label }}
      </div>

      <div v-if="hasSeenInfo" class="answer">
        <div class="answer-wrapper">
          <div class="answer-icon">?</div>

          <div class="answer-info">
            <div class="answer-title t-h3">Bon à savoir</div>
            <div class="answer-text t-text">
              {{ data.textWin }}
            </div>
          </div>
        </div>

        <div class="answer-buttons">
          <!-- <a
            :href="data.more"
            target="_blank"
            class="answer-button answer-button-info t-text"
            >En savoir plus</a
          > -->
          <div
            class="answer-button answer-button-valid t-text"
            @click="onNext"
          >
            Continuer
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { audio } from '@/utils/Audio'

export default {
  name: "Quizz",

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    index: {
      type: Number,
      default: 0
    },
  },

  computed: {
    goodIndex () {
      return this.data.answers.findIndex(answer => !!answer.value)
    }
  },

  data() {
    return {
      activeIndex: 0,
      hasAnswered: false,
      hasSeenInfo: false
    }
  },

  mounted () {
    this.timeout = setTimeout(() => {
      this.$router.push({name: 'introduction'})
    }, 20000)
  },

  beforeUnmount () {
    clearTimeout(this.timeout)
  },

  methods: {
    tryAnswer(answer) {
      if (this.hasAnswered) return false

      this.hasAnswered = true

      if (answer.value) {
        audio.src = this.$store.state.global.sounds.goodAnswer
        audio.play()
      }
      else {
        audio.src = this.$store.state.global.sounds.badAnswer
        audio.play()
      }

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({name: 'introduction'})
      }, 20000)

      setTimeout(() => {
        this.hasSeenInfo = true
      }, 1500)
    },

    onNext() {
      this.$emit('next')

      audio.src = this.$store.state.global.sounds.quizz
      audio.play()
    },

    formatNumber (number) {
      if (number < 10) {
        return '0' + number.toString()
      }
      return number
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'

.quizz
  width 100%

.question
  background $colors.green
  border-radius 11.4389px
  display flex
  flex-direction column
  padding 24px 26px
  margin-top 30px
@media screen and (max-width: 1300px)
  .question
    margin-top 20px
@media screen and (max-width: 800px)
  .question
    margin-top 10px

.question-number
  text-transform uppercase
  font-weight 900
  font-size 15.9829px
  line-height 30px
  color $colors.yellow
  background white
  align-self flex-start
  padding 2px 13px
  border-radius 34.8717px
  margin-bottom 10px

.question-text
  color $colors.white

.proposition
  padding 23px 24px 24px
  background #FFFFFF
  border-radius 26.2761px
  text-align center
  color #000000
  margin-top 30px
  cursor pointer
  position relative
  overflow hidden

  &.valid
    color $colors.white
    background #00A994
    z-index 10

  &.info
    color $colors.white
    z-index 10
    background $colors.green

  &.invalid
    background #FF7D6F

  &.hidden
    display none

  &:not(.valid):not(.invalid):not(.info):hover
    background #ffffffcc

  &:after
    position absolute
    content ''
    left 0
    right 0
    top 0
    bottom 0
    mix-blend-mode multiply
    box-shadow inset 0px -12.6915px 11.7152px rgba(208, 208, 208, 0.25), inset 7.81015px -22.4542px 37.0982px #F5F1F1
    opacity 0.8

@media screen and (max-width: 1300px)
  .proposition
    margin-top 20px
@media screen and (max-width: 800px)
  .proposition
    margin-top 10px

.answer
  color white
  background #9E8C5E66
  backdrop-filter blur(50.3111px)
  border-radius 26.2736px
  display flex
  flex-direction column
  padding 100px 52px 38px
  margin-top -50px
  position relative
@media screen and (max-width: 1300px)
  .answer
    padding: 80px 32px 18px;
@media screen and (max-width: 800px)
  .answer
    padding 80px 26px 26px

.answer-wrapper
  display flex

.answer-icon
  line-height 24px
  font-weight 700
  color: $colors.yellow
  background $colors.white
  border-radius 50%
  flex 0 0 78px
  height 78px
  display flex
  justify-content center
  align-items center
  font-size 43.0032px
  transform translate(-25px, 0)
@media screen and (max-width: 1300px)
  .answer-icon
    flex 0 0 44px
    height 44px
    font-size 28px
    transform translate(-10px, 0)
@media screen and (max-width: 800px)
  .answer-icon
    flex 0 0 36px
    height 36px
    font-size 25px
    transform translate(-14px, 0)

.answer-info
  display flex
  flex-direction column

.answer-title
  margin-bottom 10px

.answer-text
  cursor help

.answer-buttons
  display flex
  justify-content center
  margin-top 54px
@media screen and (max-width: 1300px)
  .answer-buttons
    margin-top 22px
@media screen and (max-width: 800px)
  .answer-buttons
    margin-top 34px

.answer-button
  border-radius 95.3342px
  padding 9px 22px 12px
  flex 0 0 48%
  text-align center
  cursor pointer

.answer-button-info
  border 2px solid #FFFFFF

  &:hover
    background #3A4390

.answer-button-valid
  color: $colors.green
  border: 2px solid $colors.yellow
  background: $colors.yellow

  &:hover
    border-color: $colors.green
</style>

<template>
  <div class="u-wrapper-panel">
    <div class="u-row">
      <div class="u-w4of8 u-marg-l-md">
        <div class="u-box-1by1 u-bg-red" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',

  props: {
    msg: String
  }
}
</script>
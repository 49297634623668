<template>
  <div class="layout">
    <video class="u-wrapper-panel u-fit-cover" muted="muted" autoplay="autoplay" loop="loop" src="/videos/introduction.mp4" />
    <div class="introduction-background">
      <img src="../../assets/light.svg" />
    </div>

    <div class="introduction">
      <div class="introduction-header">
        <div class="introduction-logo">
          <Logo />
        </div>
      </div>
      <div class="introduction-content">
        <!-- <div class="introduction-illustration">
          <img
            src="../../assets/introduction-temp.png"
            alt=""
            srcset="../../assets/introduction-temp@2x.png 2x"
          />
          <video muted="muted" autoplay="autoplay" loop="loop" src="/videos/introduction.mp4" />
        </div> -->
        
        <div class="introduction-text">

          <template v-for="(text, index) in data.multipleTexts" :key="text.text">
            <transition name="t-fader--delayed">
              <message-toast
                v-if="index === currentMessage"
                :text="text.text"
                look="green"
                class="introduction-message"
                @next="nextMessage"
              />
            </transition>
          </template>

        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { Audio, audio } from '@/utils/Audio'

import Logo from "../elements/Logo.vue"
import MessageToast from "../elements/MessageToast.vue"
import VideoPlayer from "../common/players/VideoPlayer"


export default {
  name: "Introduction",

  components: {
    Logo,
    MessageToast,
    VideoPlayer
  },

  data() {
    return {
      currentMessage: -1,
      isTransitionEnd: false
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/homepage']
    },

    firstSection () {
      const sections = this.$store.getters['data/sections']
      if (sections.length) {
        return this.$store.getters['data/sections'][0]
      }
      return null
    }
  },

  created () {
    this.currentMessage++

    this.audio = new Audio()
    this.audio.player.loop = true
    this.audio.player.volume = .3
    this.audio.src = this.$store.state.global.sounds.background
  },

  methods: {
    nextMessage () {
      this.audio.play()
      audio.src = this.$store.state.global.sounds.nextLevel
      audio.play()

      if (this.currentMessage === this.data.multipleTexts.length - 1) {
        this.currentMessage++
        this.$router.push({name: 'chapters', params: {section: this.firstSection.slug}})
      }else{
        this.currentMessage++
      }
    },
    nextLevel () {
    }
  }
};
</script>

<style lang="stylus" scoped>

.layout
  position fixed
  width 100%
  height 100%
  background #004413

.introduction-background
  position absolute
  // z-index -1
  width 100%
  height 100%
  pointer-events none

  img
    object-fit cover
    width 100%
    height 100%

.introduction
  display flex
  flex-direction column
  align-items center
  justify-content space-between
  height 100%

.introduction-content
  display flex
  flex 1
  width: 100%;
  justify-content: center;
@media screen and (max-width: 1200px)
  .introduction-content
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

.introduction-logo
  margin-top: 76px;
  max-width 180px
@media screen and (max-width: 1200px)
  .introduction-logo
    margin-top: 46px;
@media screen and (max-width: 800px)
  .introduction-logo
    margin-top: 43px;

.introduction-text
  position relative
  max-width 780px
  margin 82px 40px
@media screen and (max-width: 1200px)
  .introduction-text
    width: 600px;
    margin: 0 40px 12px;
    transform: translate(0, -50px);
    bottom: 0;
    z-index 10
@media screen and (max-width: 800px)
  .introduction-text
    position: absolute;
    transform: translate(0, 0);
    max-width: 450px;
    margin: 22px 20px 36px;
    display: flex;
    flex-direction: column-reverse;
    
.introduction-message
  width 100%
  @media screen and (max-width: 1200px)
    position absolute
    top 0
    left 0

.introduction-illustration
  display flex
  justify-content: flex-end;
  position: relative;
  z-index: -1;
  flex: 0 0 30%;
  video
    align-self: flex-end;
    position: fixed;
    bottom: 0;
    width: 40%;
    height: auto;
    max-width: 640px;
@media screen and (max-width: 1620px)
  .introduction-illustration
    video
      left 0
@media screen and (max-width: 1200px)
  .introduction-illustration
    width 290px
    video
      position: relative;
      bottom: inherit;
      width 100%
      height auto
@media screen and (max-width: 800px)
  .introduction-illustration
    width 230px
</style>

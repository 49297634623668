<template>
  <div class="layout">
    <video-player
      v-if="!switchVideo"
      :forcePlay="true"
      @ended="onEndedVideo"
      :src="data.video[0].url"
      :isStoppable="false"
      class="u-wrapper-panel u-fit-cover"
    />
    <video-player
      v-else
      :forcePlay="true"
      :loop="!listenEndVideo"
      @ended="onEndedLoop"
      :src="data.video[1].url"
      :isStoppable="false"
      class="u-wrapper-panel u-fit-cover"
    />

    <div class="site-logo">
      <Logo />
    </div>

    <transition name="t-fader">
      <div v-if="currentQuizz >= 0" class="chapter-pannel u-invisible-scroll">
        <chapter-toast
          :class="[{isMobileHidden: true}]"
          :number="data.index + 1"
          :text="data.title"
        />
        <div class="quizz-pannel">
          <template v-for="(question, index) in data.questions">
            <transition name="t-fader-absolute-top--delayed">
              <quizz v-if="index === currentQuizz" :index="index" :data="question" @next="nextQuizz" />
            </transition>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Logo from "../elements/Logo.vue"
import ChapterToast from "../elements/ChapterToast.vue"
import AppPhone from "../elements/AppPhone.vue"
import Quizz from "../elements/Quizz.vue"
import BurgerPannel from "../elements/BurgerPannel.vue"
import MessageToast from "../elements/MessageToast.vue"
import VideoPlayer from "../common/players/VideoPlayer"

export default {
  name: "Chapters",
  components: {
    Logo,
    ChapterToast,
    Quizz,
    BurgerPannel,
    MessageToast,
    VideoPlayer,
    AppPhone
  },
  data() {
    return {
      currentQuizz: -1,
      switchVideo: false,
      listenEndVideo: false,
    };
  },

  created () {
    // this.currentMessage++
    // this.currentQuizz++
  },

  mounted () {
    // this.isMenuAnimation = true

    // setTimeout(() => {
    //   this.isMenuAnimation = false

    //   setTimeout(() => {
    //     this.currentMessage = 0
    //   }, 2500)
    // }, 2500)
  },

  computed: {
    chapters() {
      return this.$store.getters["data/chapters"]
    },
    quizz() {
      return this.chapters[0].steps[0]?.quizz
    },
    message() {
      return this.chapters[0].steps[0]?.message
    },
    sections () {
      return this.$store.getters['data/sections']
    },
    data () {
      return this.$store.getters['data/sectionBySlug'](this.$route.params.section)
    },
  },
  methods: {
    nextMessage () {
      this.currentMessage++
    },
    nextQuizz () {
      this.currentQuizz++
    },
    nextIllu () {
      this.currentIllu++
    },
    onEndedLoop () {

      // if (this.listenEndVideo) {
      //   this.switchVideo = true
      // }
    },
    onEndedVideo () {
      this.nextQuizz()
      this.switchVideo = true
    //   const next = this.sections[this.data.index + 1]
    //   if (next) {
    //     this.isMenuAnimation = true
    //     this.$router.push({name: 'chapters', params: {section: next.slug}})
    //   }
    //   else {
    //     this.$router.push({name: 'introduction'})
    //   }
    }
  },

  watch: {
    $route () {
      this.listenEndVideo = false
      this.switchVideo = false
      this.currentQuizz = -1

      // setTimeout(() => {
      //   this.isMenuAnimation = false
      //   setTimeout(() => {
      //     this.currentMessage = 0
      //   }, 1000)
      // }, 2500)
    },

    currentQuizz () {
      if (this.data && this.currentQuizz >= this.data.questions.length) {
        const next = this.sections[this.data.index + 1]
        if (next) {
          this.$router.push({name: 'chapters', params: {section: next.slug}})
        }
        else {
          this.$router.push({name: 'introduction'})
        }
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'

.layout
  position fixed
  background-size cover
  width 100%
  height 100%

.site-logo
  position fixed
  z-index 100
  bottom 0
  left 0
  margin 55px
  width 80px
  padding 10px
  
@media screen and (max-width: 1300px)
  .site-logo
    margin 35px
@media screen and (max-width: 800px)
  .site-logo
    margin 33px 20px

.chapter-pannel
  position fixed
  z-index 20
  top 0
  right 0
  bottom 0
  padding 36px 26px
  display flex
  flex-direction column
  align-items center
  max-width 528px
  width 100%
  overflow-y auto

@media screen and (max-width: 1300px)
  .chapter-pannel
    max-width 400px

@media screen and (max-width: 800px)
  .chapter-pannel
    max-width 100%
    width 100%
    justify-content flex-end
    top: auto;
    padding-top: 0;

    .isMobileHidden
      display: none;

.quizz-pannel
  display flex;
  flex-direction column
  width 100%
@media screen and (max-width: 800px)
  .quizz-pannel
    flex-direction column-reverse

.message-pannel
  position fixed
  z-index: 20
  bottom 0
  left 0
  right 0
  margin 0 26px 36px
  display: flex
  justify-content: center
  flex-direction: column-reverse

</style>
